// FeedbackModal.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import "./LoginPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Navbar from "../../components/Navbar/Navbar";
import Bottom from "../../components/Bottom/Bottom";
import { Navigate, useNavigate } from "react-router-dom";
const apiUrl = process.env.REACT_APP_API_URL;

const LoginPage = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const formData = {
    email: email,
    password: password,
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}/api/v1/admin/login`, {
        email: formData.email,
        password: formData.password,
      });
      // console.log(response);
      navigate("/");
    } catch (error) {
      console.error("Error Logging In User : ", error);
    }

    setEmail("");
    setPassword("");
  };

  return (
    <div className="login-div">
      <div>
        <Navbar />
      </div>

      <div className="form-container">
        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
          </div>
          <button className="login-button" type="submit">
            Login
          </button>
        </form>
      </div>
      <div>
        <Bottom />
      </div>
    </div>
  );
};

export default LoginPage;