import React, { useState,useEffect } from 'react';
import './Bottom.css';

const apiUrl = process.env.REACT_APP_API_URL;

const Bottom = () => {
  
  const[userCount,setUserCount]=useState(0);
  const[mantraCount,setMantraCount]=useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/v1/user/count`);
        if (!response.ok) {
          throw new Error('Network Response Was Not Okay.');
        }

        const data = await response.json();
        setUserCount(data.data.count);
      } catch (error) {
        console.error('Error Fetching User Count :', error);
      }

      try {
        const response = await fetch(`${apiUrl}/api/v1/mantralekhan/count`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setMantraCount(data.data.count);
      } catch (error) {
        console.error('Error Fetching Mantra Count:', error);
      }
    };

    fetchData();
  }, []);
  
  return (
      <div className="bottom-parent">
       <div className="row mantralekhanFooter">
          <p className="text-center m-2">
            Developed & Maintained by{' '}
            <a
              className="fw-bold"
              href="https://www.flutebyte.com"
              target="_blank"
            >
              Flutebyte Technologies
            </a>
          </p>
        </div>
      </div>
  );
};

export default Bottom;
