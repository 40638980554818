
import React, { useState,useEffect } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import './AllUsers.css';
import Bottom from '../../components/Bottom/Bottom';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const AllUsers = () => {
    const[userData,setUserData]=useState(null);
    const [usersData,setUsersData]=useState([]);

    useEffect(() => {

      const getUserData = async () => {
        try {
          axios.defaults.withCredentials = true;
          let response = await axios(`${apiUrl}/api/v1/admin/login/success`, {
            method: 'GET',
            withCredentials: true
          })

          setUserData(response.data.data);
          getUsersData();

        } catch (error) {
          console.log("Error Fetching User Data.", error);
          window.location.href = "/login";
        }
      };
  
      
      const getUsersData = async () => {
        try {
          const response = await fetch(`${apiUrl}/api/v1/admin/users`, {
            method: 'GET',
            credentials: 'include'
          });
          const data = await response.json();

          // console.log(response);
          setUsersData(data.data);

          if (!response.ok) {
            throw new Error('Network Response Was Not Okay.');
          }

          // console.log(data);
         
        } catch (error) {
          console.log("Error fetching user data:", error);
        }
      };
      getUserData();
    }, []);

    function convertISOToCustomFormat(isoString) {
      const date = new Date(isoString);
      
      const day = date.getUTCDate().toString().padStart(2, '0');
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const month = monthNames[date.getUTCMonth()];
      const year = date.getUTCFullYear();
      
      let hours = date.getUTCHours();
      const minutes = date.getUTCMinutes().toString().padStart(2, '0');
      const ampm = hours >= 12 ? 'PM' : 'AM';
      
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      
      return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
    }

    return (
      <div className='allusers-main'>
        <div>
          <Navbar/>
        </div>
        <div className='d-flex justify-content-center table-box'>
          <div className='table-container' style={{ width: '80%' }}>
            <div className="table-responsive">
              <table className="table table-bordered">
                {/* <caption>All Users</caption> */}
                <thead style={{ backgroundColor: '#de4620', color: '#fff' }}>
                  <tr>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>Address</th>
                    <th>Mantra Counted</th>
                    {/* <th>Last Active</th> */}
                  </tr>
                </thead>
                <tbody>
                  {usersData.map((user, index) => (
                    <tr key={index}>
                      <td>{user.fullName}</td>
                      <td>{user.email}</td>
                      <td>{user.phone}</td>
                      <td>{user.address}</td>
                      <td>{user.mantraChanted}</td>
                      {/* <td>{user.updatedAt}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
          <Bottom/>
        </div>
      </div>
    );
}

export default AllUsers